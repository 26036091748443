<template>
  <div class="auth-wrapper auth-v2">
    <b-modal
      v-model="modalExport"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      modal-class="modal-primary"
      centered
      title="O que deseja exportar"
    >
      <b-card-text>
        Selecione quais informações deseja exportar
        <!-- Checkboxes for each data attribute -->
        <div>
          <label>
            <input :disabled="downloadLink" type="checkbox" v-model="collaborators" />
            Colaboradores
          </label>
        </div>
        <div>
          <label>
            <input :disabled="downloadLink" type="checkbox" v-model="projects" />
            Projetos
          </label>
        </div>
        <div>
          <label>
            <input :disabled="downloadLink" type="checkbox" v-model="tasks" />
            Tarefas
          </label>
        </div>
        <div>
          <label>
            <input :disabled="downloadLink" type="checkbox" v-model="financial" />
            Financeiro
          </label>
        </div>
        <div>
          <label>
            <input :disabled="downloadLink" type="checkbox" v-model="suppliers" />
            Fornecedores
          </label>
        </div>
        <div>
          <label>
            <input :disabled="downloadLink" type="checkbox" v-model="goods" />
            Produtos
          </label>
        </div>
      </b-card-text>
      <!-- Button to trigger the export function -->
      <b-button v-if="!downloadLink" variant="primary" class="mt-3" block @click="_export">Exportar</b-button>

      <a v-if="downloadLink" finalLink class="mt-3" @click="download">
        <b-button block variant="success" class="mt-3">Baixar</b-button>
      </a>
      <b-button variant="warning" class="mt-1" block @click="$router.push('/')">Voltar</b-button>
    </b-modal>
  </div>
</template>
<script>
import { BModal, BCardText, BButton } from 'bootstrap-vue';
import { utils, writeFile } from 'xlsx';

export default {
  components: {
    BModal,
    BCardText,
    BButton
  },
  data() {
    return {
      collaborators: true,
      projects: true,
      tasks: true,
      financial: true,
      suppliers: true,
      goods: true,
      modalExport: true,
      downloadLink: false
    };
  },
  computed: {},
  methods: {
    _export() {
      this.$store
        .dispatch('getExportFile', {
          collaborators: this.collaborators,
          projects: this.projects,
          tasks: this.tasks,
          financial: this.financial,
          suppliers: this.suppliers,
          goods: this.goods,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(response => {
          console.log('response', response.data.data);

          var wb = utils.book_new();

          const data = response.data.data;
          var invoices = [];
          var spreadSheets = [];

          if (data.members) {
            let userSheet = data.members;
            let invoices = userSheet.map(item => ({
              Nome: item.username,
              Ativo: item.active ? 'Sim' : 'Não',
              Custo: this.numberToMonetary(item.cost_per_month),
              Horas: item.hour_per_month,
              Improdutividade: item.unproductively,
              Cor: item.color
            }));

            let invoicesWS = utils.json_to_sheet(invoices);
            invoicesWS['!cols'] = [];
            userSheet.forEach((item, index) => {
              let cellAddress = `G${index + 2}`;
              if (item.color) {
                invoicesWS[cellAddress] = {
                  v: item.color,
                  s: {
                    fill: {
                      fgColor: { rgb: item.color.replace('#', '') }
                    }
                  }
                };
              }
            });

            spreadSheets.push({ sheet: invoicesWS, name: 'Membros do projeto' });
          }

          if (data.colaberCost) {
            let userSheet = data.colaberCost;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                Valor: item.value,
                Mês: item.month,
                Ano: item.year
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Custo de colaborador' });
          }

          if (data.goods) {
            let userSheet = data.goods;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                Nome: item.item_name,
                Data_de_aquisicao: this.dateFormatter(item.acquisition_date),
                Valor_de_asquisicao: this.numberToMonetary(item.acquisition_value),
                Valor_residual: this.numberToMonetary(item.residual_value),
                Depreciacao: item.depreciation,
                Vida_util: item.lifespan_months
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Bens depreciáveis' });
          }

          if (data.goodsHistory) {
            let userSheet = data.goodsHistory;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                id_bem: item.good_id,
                Depreciacao: this.numberToMonetary(item.depreciation_amount),
                Mes: item.mounth,
                Ano: item.year
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Depreciação' });
          }

          if (data.projects) {
            let userSheet = data.projects;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                Codigo: item.project_code,
                Nome: item.project_name,
                Tipo: item.project_type,
                Valor: this.numberToMonetary(item.project_value),
                Deadline: item.deadline ? `${item.deadline} meses` : '',
                Data_contrato: this.dateFormatter(item.contract_date),
                Previsao: item.finish_date,
                Cliente: item.client_name,
                Doc_contato: item.contact_document,
                Contato: item.contact_name,
                Contato_cliente: item.contact_phone,
                Arquivado: item.is_archived ? 'Sim' : 'Não'
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Projetos' });
          }

          if (data.suppliers) {
            let userSheet = data.suppliers;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                Nome: item.supplier_name
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Fornecedores' });
          }

          if (data.tasks) {
            let userSheet = data.tasks;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                Nome: item.title,
                Projeto: item.project_name,
                Data: this.dateFormatter(item.date),
                Inicio: item.start.slice(0, 5),
                Fim: item.end.slice(0, 5),
                Custo_indireto: item.indirect_cost,
                Custo_real_indireto: item.real_indirect_cost,
                Custo_direto: item.direct_cost,
                Custo_real_direto: item.real_direct_cost,
                Notas: item.notes
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Tarefas' });
          }

          if (data.transaction) {
            let userSheet = data.transaction;
            invoices = userSheet.reduce((ac, item) => {
              ac.push({
                Descricao: item.description,
                Despesa: item.expense == false ? 'Não' : 'Sim',
                Grupo: item.group === 'offices' ? 'Escritorio' : 'Projeto',
                Status: item.status,
                Referencia: this.dateFormatter(item.reference_date),
                Repete: item.repeat == 0 ? 'Não' : item.repeat,
                Valor: this.numberToMonetary(item.value)
              });
              return ac;
            }, []);
            let invoicesWS = utils.json_to_sheet(invoices);
            spreadSheets.push({ sheet: invoicesWS, name: 'Taxas-Impostos' });
          }

          spreadSheets.forEach(sheetObj => {
            utils.book_append_sheet(wb, sheetObj.sheet, sheetObj.name);
          });

          writeFile(wb, 'Relatório.xlsx');
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
